<template>
  <div class="div">
    <img class="img" src="@/assets/imgs/dpbb.png" alt="" />
    <div class="box">
      <input
        class="input"
        v-model="input"
        type="password"
        @keyup.enter="changey"
      />
      <button class="but" @click="changey">确认</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      input: "",
    };
  },
  methods: {
    changey() {
      //   alert(this.input);
      let str = this.input;
      this.input = "";
      let url = "/authorization/authorizationScreenWhite/loginScreen";
      let data = {
        pwd: str,
      };
      this.$axios.post(url, data).then((res) => {
        if (res.data.message == "成功") {
          this.$router.push({
            name: "Dapingmu",
            query: {
              data: JSON.stringify(res.data.data),
            },
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.div {
  width: 100% !important;
  position: relative;
}
.img {
  width: 99%;
  height: 853px;
  @media screen and(max-width: 768px) {
    height: 300px;
  }
}
.box {
  position: absolute;
  top: 51%;
  left: 14.5%;
  @media screen and(max-width: 768px) {
    top: 45%;
  }
}
.input {
  /* border: none; */
  width: 100%;
  height: 55px;
  border: 3px solid #c5c2c2;
  font-size: 24px;   
  @media screen and(max-width: 768px) {
    height: 30px;
    width: 90%;
  }
}
.but {
  width: 268px;
  height: 50px;
  /* background: #ffa53e; */
  opacity: 0;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 9px;
  position: absolute;
  top: 102px;
  right: 31px;
  @media screen and(max-width: 768px) {
    height: 40px;
    top: 50px;
  }
}
</style>
